<template>
  <div class="body">
    <div class="inputBox">
      <div class="feedback2">意见反馈</div>
      <div class="inputBox1">
        <textarea class="inputBox2" placeholder="请输入500字内反馈内容" :maxlength="500" @input="descInput" v-model="queryParams.feedbackContent"></textarea>
        <span class="inputBox3">{{remnant}}/500</span>
      </div>
      <div class="sumbmit">
        <span class="sumbmit1" @click="demo()">提交</span>
      </div>
    </div>
  </div>
</template>

<script>
import { updateOpinion } from "@/api/feedback";
export default {
  name: "Feedback",
  components: {},
  data() {
    return {
      remnant: 0,
      queryParams: {
        feedbackContent: null,
        feedbackChannel: "1"
      }
    }
  },
  methods: {
    descInput(){
      var txtVal = this.queryParams.feedbackContent.length;
      this.remnant = txtVal;
    },
    demo(){
      updateOpinion(this.queryParams).then((res) => {
        alert(res.data);
      });
    }
  }
};
</script>

<style scoped>
.feedback {
  width: 250px;
  height: 900px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  margin-left: 350px;
  margin-top: 64px;
  margin-bottom: 40px;
  flex-direction: column;
  display: inline-flex;
}

.problem {
  width: 250px;
  height: 105px;
  opacity: 1;
  background: #ffffff;
  display: inline-flex;
}

.feedback1 {
  width: 250px;
  height: 104px;
  opacity: 1;
  background: #ffffff;
  display: inline-flex;
}

.inputBox {
  width: 930px;
  height: 900px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  margin-left: 20px;
  margin-top: 64px;
  margin-bottom: 40px;
  display: inline-flex;
  flex-direction: column;
}

.body {
  display: flex;
}

.font {
  width: 250px;
  height: 105px;
  opacity: 1;
  background: #ffffff;
  line-height: 105px;
  text-align: center;
  font-size: 20px;
}

.feedback2 {
  width: 80px;
  height: 20px;
  opacity: 1;
  font-size: 20px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #1f1f1f;
  line-height: 20px;
  margin-left: 40px;
  margin-top: 42px;
  display: inline-flex;
  flex-direction: column;
}

.inputBox1 {
  width: 850px;
  height: 338px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e0e2e8;
  margin-left: 40px;
  margin-top: 50px;
  display: inline-flex;
  flex-direction: column;
}

.inputBox2 {
  width: 850px;
  height: 338px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e0e2e8;
  display: inline-flex;
  flex-direction: column;
}

.sumbmit {
  width: 160px;
  height: 48px;
  opacity: 1;
  background: linear-gradient(106deg, #f7d9b7 0%, #eabf96 100%);
  border-radius: 2px;
  margin-left: 40px;
  margin-top: 40px;
  text-align: center;
  line-height: 48px;
}
.sumbmit1 {
  width: 40px;
  height: 20px;
  opacity: 1;
  font-size: 20px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #b88141;
  line-height: 20px;
}
.inputBox3{
  width: 39px;
  height: 14px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #8691a8;
  line-height: 14px;
  margin-left: 800px;
}
</style>
