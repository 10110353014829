import request from '@/utils/request'

// 意见反馈
export function updateOpinion(data) {

  return request({
    url: '/api/system/custservice/complaint/insert',
    method: 'post',
    data
  })
}




